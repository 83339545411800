import React from 'react';
import CategoryPage from '../components/CategoryPage';

export const query = graphql`
  query CategoryQuery($uid: String) {
    prismic {
      category: allCategorys(uid: $uid) {
        edges {
          node {
            title
            projects {
              node: project {
                ... on PRISMIC_Project {
                  title
                  length
                  type
                  number
                  video_thumbnail
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
      category_list(uid: "categories", lang: "en-gb") {
        categories {
          category {
            ... on PRISMIC_Category {
              title
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

const Category = ({ data }) => {
  const projects = data?.prismic?.category?.edges[0]?.node?.projects;
  const categories = data?.prismic?.category_list?.categories;

  const hasProjects =
    data?.prismic?.category?.edges[0]?.node?.projects[0]?.node !== null;

  if (!projects || !hasProjects || !categories) {
    return null;
  }

  return <CategoryPage projects={projects} categories={categories} />;
};

export default Category;
