import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import LazyImg from '../components/LazyImg';
import getImageUrl from '../getImageUrl';
import { yellow, red, green, desktop } from '../constants';
import Title from '../components/Title';
import SEO from '../components/seo';

const CategoryList = styled.ul`
  text-align: center;
  margin-bottom: 4rem;
`;

const CategoryListItem = styled.li`
  display: inline-flex;
  font-weight: 300;
  font-size: 1.25rem;
  color: ${red};
  padding: 0.25rem;

  @media (min-width: ${desktop}) {
    font-size: 1.75rem;
  }

  ::after {
    content: '/';
    display: inline-block;
    padding-left: 0.5rem;
  }

  &:last-child {
    ::after {
      content: '';
    }
  }
`;

const Projects = styled.ul`
  margin: 0 auto;

  @media (min-width: 30rem) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Project = styled.li`
  margin-bottom: 2rem;
  font-weight: 800;
  font-size: 1.25rem;
  margin: 0 0 2rem;

  ${({ fullWidth }) =>
    !fullWidth
      ? `@media (min-width: 30rem) {
    display: flex;
    width: 49%;
  }`
      : 'width: 100%;'}
`;

const ProjectImage = styled(LazyImg)`
  width: 100%;
`;

const ProjectInfo = styled.div`
  padding: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${red};
  width: 100%;

  &:visited,
  &:active {
    color: ${red};
  }

  &:hover ${ProjectInfo} {
    background-color: ${yellow};
  }

  &[aria-current='page'] {
    text-decoration: underline;
  }
`;

const ProjectData = styled.div`
  color: ${green};
  font-weight: 300;
  font-size: 1rem;
  margin-top: 0.5rem;
  text-transform: lowercase;

  @media (min-width: ${desktop}) {
    font-size: 1.125rem;
    line-height: 1.5;
  }
`;

const ProjectTitle = styled.h3`
  @media (min-width: ${desktop}) {
    font-size: 2rem;
    line-height: 1.25;
  }
`;

const Work = ({ projects, categories }) => (
  <Layout>
    <SEO title="Work" />
    <Title>Work</Title>
    <CategoryList>
      {categories && categories.map(({ category }) => (
        <CategoryListItem key={category?._meta?.uid}>
          <StyledLink to={`/category/${category?._meta?.uid}`}>
            {RichText.asText(category?.title)}
          </StyledLink>
        </CategoryListItem>
      ))}
    </CategoryList>
    <Projects>
      {projects && projects.map((project, index) => {
        if (!project.node) {
          return null;
        }

        const { length, number, type } = project?.node;
        const fullWidth = (index + 1) % 5 === 0 || projects.length === 1;

        return (
          <Project fullWidth={fullWidth} key={project?.node?._meta?.uid}>
            <StyledLink to={`/project/${project?.node?._meta?.uid}`}>
              <ProjectImage
                src={getImageUrl(
                  project?.node?.video_thumbnail?.thumbnail_url
                )}
                alt={project?.node?.video_thumbnail?.title}
                fullWidth={fullWidth}
                key={project?.node?._meta?.uid}
              />
              <ProjectInfo>
                <ProjectTitle>
                  {RichText.asText(project?.node?.title)}
                </ProjectTitle>
                <ProjectData>{`${length} minute${
                  length > 1 ? 's' : ''
                } • ${type ? `${RichText.asText(type)} • `: ''}${number} episode${
                  number > 1 ? 's' : ''
                }`}</ProjectData>
              </ProjectInfo>
            </StyledLink>
          </Project>
        );
      })}
    </Projects>
  </Layout>
);

export default Work;
